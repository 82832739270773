import React from 'react';
import './footer.css';

const footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <h3>om oss</h3>
          <p>Vi vill att det ska vara kul att lära sig, och nå sina mål och drömmar i livet.</p>
        </div>
        <div className="footer-section">
          <h3>snabb länkar</h3>
          <ul>
            <li><a href="/">Hem</a></li>
            <li><a href="/services">StudlyPRO</a></li>
            <li><a href="/about">Kurser</a></li>
            <li><a href="/contact">MeritRäknaren</a></li>
          </ul>
        </div>
        <div className="footer-section">
          <h3>kontakt</h3>
          <p>Email: hej@hej.com</p>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 Studly.nu All rights reserved.</p>
      </div>
    </footer>
  );
};

export default footer;
