import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './header';
import Footer from './footer';
import Login from './Login';
import Register from './Register';
import Overview from './Overview';
import Profile from './Profile';
import Merit from './Merit';
import EmailVerification from './EmailVerification';
import Home from './Home';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import Membership from './Membership';
import Courses from './Courses';
import CookiePopup from './CookiePopup';
import Matematik1a from './Courses/Matematik1a';
import Matematik3c from './Courses/Matematik3c/Matematik3c';
import Matematik4 from './Courses/Matematik4';
import './App.css';
import Quiz from './Quiz';

function App() {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
      fetch('https://www.studly.nu/current_user', {
          method: 'GET',
          credentials: 'include',
      })
          .then(response => response.json())
          .then(data => {
              if (data.username) {
                  setCurrentUser(data.username);
              }
          })
          .catch(() => {});
  }, []);

  const handleLogout = () => {
      fetch('https://www.studly.nu/logout', {
          method: 'POST',
          credentials: 'include',
      })
          .then(() => {
              setCurrentUser(null);
          })
          .catch(() => {});
  };

  return (
      <Router>
          <div className="App">
              <Header currentUser={currentUser} handleLogout={handleLogout} />
              <main>
                  <Routes>
                      {currentUser ? (
                          <Route path="/" element={<Navigate to="/Overview" />} />
                      ) : (
                        <Route path="/" element={<Home />} />
                      )}
                      <Route path="/Overview" element={<Overview />} />
                      <Route path="/login" element={<Login setCurrentUser={setCurrentUser} />} />
                      <Route path="/register" element={<Register />} />
                      <Route path="/forgot_password" element={<ForgotPassword />} />
                      <Route path="/reset_password" element={<ResetPassword />} />
                      <Route path="/courses/Matematik4" element={<Matematik4 />} />
                      <Route path="/verify_code" element={<EmailVerification />} />
                      <Route path="/verify" element={<EmailVerification />} />
                      <Route path="/Quiz" element={<Quiz />} />
                      <Route path="/courses/Matematik3c" element={<Matematik3c />} />
                      <Route path="/courses/Matematik1a" element={<Matematik1a />} />
                      <Route path="/Merit" element={<Merit />} />
                      <Route path="/courses" element={<Courses />} />
                      {currentUser && (
                          <Route path="/profile" element={<Profile />} />
        
                      )}
                      {currentUser && (
                          <Route path="/membership" element={<Membership />} />
        
                      )}
                  </Routes>
              </main>
              <Footer />
              <CookiePopup />
          </div>
      </Router>
  );
}

export default App;
