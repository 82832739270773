import React, { useState, useEffect } from 'react';
import './Overview.css';

const Overview = () => {
  const [username, setUsername] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('https://www.studly.nu/current_user', {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch username");
        }
        return response.json();
      })
      .then((data) => {
        if (data.error) {
          setError(data.error);
        } else {
          setUsername(data.username);
        }
      })
      .catch((error) => {
        setError(error.message);
      });
  }, []);

  if (error) return <p>Error: {error}</p>;

  return (
    <div className="welcome-container">
      <h1>Welcome {username || "Guest"}</h1>
    </div>
  );
};

export default Overview;
