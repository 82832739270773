import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';
import './Home.css';
import Logo from './Icons/homeImg1.svg';
import Logo2 from './Icons/homeImg2.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrown } from '@fortawesome/free-solid-svg-icons';

const Home = () => {
    // Scrolling animation triggers
    const [refHero, inViewHero] = useInView({ triggerOnce: true, threshold: 0.2 });
    const [refFeatures, inViewFeatures] = useInView({ triggerOnce: true, threshold: 0.3 });
    const [refStudy, inViewStudy] = useInView({ triggerOnce: true, threshold: 0.3 });
    const [refGetStarted, inViewGetStarted] = useInView({ triggerOnce: true, threshold: 0.3 });
    const [refPricing, inViewPricing] = useInView({ triggerOnce: true, threshold: 0.3 });

    const animationVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
    };

    const navigate = useNavigate();

    return (
        <div className="home-container">
            {/* Hero Section */}
            <motion.section
                className="hero-section"
                ref={refHero}
                initial="hidden"
                animate={inViewHero ? 'visible' : 'hidden'}
                variants={animationVariants}
            >
                <h1>
                    Ett lättare sätt att lära sig <br />
                    och klara skolan på <span className="highlight">riktigt</span>.
                </h1>
                <motion.button
                    className="get-started-button"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={() => navigate('/register')}
                >
                    Kom igång
                </motion.button>
            </motion.section>

            {/* Features Section */}
            <motion.div
                className="section-container"
                ref={refFeatures}
                initial="hidden"
                animate={inViewFeatures ? 'visible' : 'hidden'}
                variants={animationVariants}
            >
                <section className="features-section">
                    <h2>Studly erbjuder</h2>
                    <div className="features">
                        {['Bra uppgifter', 'Många kurser', 'Många kurser'].map((title, index) => (
                            <motion.div
                                key={index}
                                className="feature"
                                custom={index}
                                variants={{
                                    hidden: { opacity: 0, x: -100 },
                                    visible: (i) => ({
                                        opacity: 1,
                                        x: 0,
                                        transition: { delay: i * 0.2, duration: 0.6 },
                                    }),
                                }}
                                initial="hidden"
                                animate={inViewFeatures ? 'visible' : 'hidden'}
                            >
                                <h3>{title}</h3>
                                <p>Bra uppgifter med olika lösningar, förklaringar och nivåer</p>
                            </motion.div>
                        ))}
                    </div>
                </section>
            </motion.div>

            {/* Study Section */}
            <motion.div
                className="study-section"
                ref={refStudy}
                initial="hidden"
                animate={inViewStudy ? 'visible' : 'hidden'}
                variants={animationVariants}
            >
                <motion.div className="study-content" variants={animationVariants}>
                    <div className="number-badge">
                        <p>Plugga som ett proffs</p>
                    </div>
                    <p className="description">
                        Här hittar du allt du behöver för att lyckas med dina studier oavsett om du vill förbättra dina betyg, förbereda dig inför ett prov, eller bara få en bättre förståelse för dina skolämnen – vi har verktygen och tipsen som hjälper dig. Börja plugga smartare redan idag och ta dina studieresultat till nästa nivå.
                    </p>

                    <div className="features-list">
                        {['Smarta studietips för bättre resultat', 'Lätt att använda, enkelt att lära', 'Plugga när och var du vill', 'Lär dig i din egen takt'].map((item, index) => (
                            <motion.div key={index} className="feature-item" variants={animationVariants}>
                                <span className="checkmark">✓</span>
                                <p>{item}</p>
                            </motion.div>
                        ))}
                    </div>
                </motion.div>

                <motion.div className="phone-mockup" variants={animationVariants}>
                    <img src={Logo} alt="App preview" className="main-phone1" />
                </motion.div>
            </motion.div>

            {/* Get Started Section */}
            <motion.div
                className="get-started-section"
                ref={refGetStarted}
                initial="hidden"
                animate={inViewGetStarted ? 'visible' : 'hidden'}
                variants={animationVariants}
            >
                <div className="get-started-content">
                    <motion.div className="phone-mockup-left" variants={animationVariants}>
                        <img src={Logo2} alt="App preview" className="main-phone2" />
                    </motion.div>

                    <motion.div className="content-right" variants={animationVariants}>
                        <div className="number-badge">
                            <p>Börja redan idag!</p>
                        </div>
                        <p className="description2">
                            Att komma igång är enkelt, ta första steget mot bättre studierutiner och högre betyg redan idag. Logga in, välj ett ämne, och upptäck hur roligt och effektivt pluggandet kan vara!
                        </p>

                        <motion.div className="testimonials" variants={animationVariants}>
                            {[
                                { name: 'Gustav 18år', quote: 'Tack vare Studly så kan jag nu äntligen plugga när jag vill.' },
                                { name: 'Johan 26år', quote: 'Tack vare Studly så kan jag nu äntligen plugga när jag vill.' },
                            ].map((testimonial, index) => (
                                <motion.div key={index} className="testimonial-card" variants={animationVariants}>
                                    <h3>{testimonial.name}</h3>
                                    <p>{testimonial.quote}</p>
                                </motion.div>
                            ))}
                        </motion.div>
                    </motion.div>
                </div>
            </motion.div>

            {/* Pricing Section */}
            <motion.section
                id="pricing-section" // Add this id for scrolling
                className="pricing-section"
                ref={refPricing}
                initial="hidden"
                animate={inViewPricing ? 'visible' : 'hidden'}
                variants={animationVariants}
            >
                <div className="crown-container">
                    <FontAwesomeIcon icon={faCrown} className="crown-icon" />
                </div>
                <h2>Skaffa Studly Pro</h2>
                <p className='underHeaderSub'>Skaffa Studly Pro idag och bli ett proffs på plugget. Med bättre verktyg och material så kan du både lära dig mer och mycket snabbare.</p>
                <div className="pricing-cards">
                    {/* Free Plan */}
                    <motion.div className="pricing-card-popular1" variants={animationVariants}>
                        <p className="popular-text1">Populär</p>
                        <h3 className="card-popular1">Studly Standard</h3>
                        <p className="under-h3-popular1">
                            Standard version av Studly med alla verktyg du behöver för att lära dig.
                        </p>
                        <p className="price-popular1">Gratis</p>

                        <p className="feature-popular1">- Full tillgång till alla kurser</p>
                        <p className="feature-popular1">- Interaktiva övningar</p>
                        <p className="feature-popular1">- Offlineläge för studier</p>
                        <p className="feature-popular1">- Prioriterad support</p>
                        <p className="feature-popular1">- Anpassade lärplaner</p>
                        <p className="feature-popular1">- Anpassade lärplaner</p>
                        <button className="get-pro-button-popular-standard">Aktiv plan</button>
                    </motion.div>

                    {/* Monthly Plan */}
                    <motion.div className="pricing-card-popular1" variants={animationVariants}>
                        <p className="popular-text1">Populär</p>
                        <h3 className="card-popular1">Studly Pro</h3>
                        <p className="under-h3-popular1">
                            Standard version av Studly med alla verktyg du behöver för att lära dig.
                        </p>
                        <p className="price-popular1">50kr/må</p>

                        <p className="feature-popular1">- Full tillgång till alla kurser</p>
                        <p className="feature-popular1">- Interaktiva övningar</p>
                        <p className="feature-popular1">- Offlineläge för studier</p>
                        <p className="feature-popular1">- Prioriterad support</p>
                        <p className="feature-popular1">- Anpassade lärplaner</p>
                        <p className="feature-popular1">- Anpassade lärplaner</p>
                        <button className="get-pro-button-popular1">Skaffa StudlyPRO</button>
                    </motion.div>

                    {/* Popular Plan */}
                    <motion.div className="pricing-card-popular" variants={animationVariants}>
                        <p className="popular-text">Populär</p>
                        <h3 className="card-popular">Studly Pro</h3>
                        <p className="under-h3-popular">
                            Standard version av Studly med alla verktyg du behöver för att lära dig.
                        </p>
                        <p className="price-popular">200kr/6må</p>

                        <p className="feature-popular">- Full tillgång till alla kurser</p>
                        <p className="feature-popular">- Interaktiva övningar</p>
                        <p className="feature-popular">- Offlineläge för studier</p>
                        <p className="feature-popular">- Prioriterad support</p>
                        <p className="feature-popular">- Anpassade lärplaner</p>
                        <p className="feature-popular">- Anpassade lärplaner</p>
                        <button className="get-pro-button-popular">Skaffa StudlyPRO</button>
                    </motion.div>


                </div>
            </motion.section>



        </div>
    );
};

export default Home;
