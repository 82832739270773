import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import './EmailVerification.css';
import Popupdiscrete from './Popupdiscrete';
import StudlyLogo from './Icons/StudlyLogo.svg';

function EmailVerification() {
    const location = useLocation();
    const navigate = useNavigate();
    const emailFromState = location.state?.email || ''; // Get email from state
    const [email] = useState(emailFromState);
    const [code, setCode] = useState('');
    const [popupMessage, setPopupMessage] = useState(null);

    const handleVerify = (e) => {
        e.preventDefault();

        if (!email || !code) {
            setPopupMessage({ message: 'Både e-post och kod krävs', type: 'error' });
            return;
        }

        fetch('https://www.studly.nu/verify_code', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, code }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.message) {
                    setPopupMessage({ message: data.message, type: 'success' });
                    setTimeout(() => navigate('/login'), 2000);
                } else {
                    setPopupMessage({ message: data.error, type: 'error' });
                }
            })
            .catch(() => {
                setPopupMessage({ message: 'Misslyckades med att verifiera e-post. Vänligen försök igen.', type: 'error' });
            });
    };

    return (
        <motion.div
            className="email-verification"
            initial={{ x: 100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.2, ease: 'easeOut' }}
        >
            <div className="login-logo">
                <img src={StudlyLogo} alt="Studly" className="logo-icon-login" />
            </div>
            <h2 className="verification-heading">Verifiering</h2>
            <p className='informationText'>
                Vi har skickat en verifierings kod till <span className="email-highlight">{email}</span> Följ instruktionerna för att komma igång.
            </p>
            <form onSubmit={handleVerify} className="verification-form">
                <input
                    className="verification-input"
                    id='verification-box'
                    type="text"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    placeholder="Skriv in verifierings kod här"
                    required
                />
                <button type="submit" className="verification-button">
                    Verifiera
                </button>
            </form>

            {popupMessage && (
                <Popupdiscrete
                    message={popupMessage.message}
                    type={popupMessage.type}
                    onClose={() => setPopupMessage(null)}
                />
            )}
        </motion.div>
    );
}

export default EmailVerification;
