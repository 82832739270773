import React, { useState, useEffect } from 'react';
import './CookiePopup.css'; // Optional, for styling

const CookiePopup = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const cookieConsent = localStorage.getItem('cookieConsent');
    if (!cookieConsent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    setIsVisible(false);
  };

  const handleDecline = () => {
    localStorage.setItem('cookieConsent', 'false');
    setIsVisible(false);
  };

  return (
    isVisible && (
      <div className="cookie-popup">
        <p>
        Denna webbplats använder cookies för att förbättra din upplevelse. Genom att fortsätta att surfa, godkänner du vår användning av cookies.
        </p>
        <button id='accept' onClick={handleAccept}>Tillåt alla</button>
        <button id='dont-accept' onClick={handleDecline}>Tillåt endas nödvändiga</button>
      </div>
    )
  );
};

export default CookiePopup;
