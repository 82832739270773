import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import Popupdiscrete from './Popupdiscrete';
import './Login.css';
import StudlyLogo from './Icons/StudlyLogo.svg';

function Login({ setCurrentUser }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [popup, setPopup] = useState({ message: '', type: '', visible: false }); // State for popup
    const navigate = useNavigate();

    const showPopup = (message, type = 'info') => {
        setPopup({ message, type, visible: true });
        setTimeout(() => setPopup({ message: '', type: '', visible: false }), 3000); // Auto-hide after 3 seconds
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        fetch('https://www.studly.nu/login', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify({ username, password }),
        })
            .then(response => response.json())
            .then(data => {
                if (data.username) {
                    setCurrentUser(data.username);
                    navigate('/');
                } else if (data.error === 'Vänligen verifiera din e-postadress innan du loggar in.') {
                    showPopup('Vänligen verifiera din e-postadress.', 'warning');
                    navigate('/verify_code', { state: { email: username } }); // Redirect to verify page
                } else {
                    showPopup(data.error, 'error'); // Show other errors as popup
                }
            })
            .catch(() => showPopup('Inloggning misslyckades', 'error')); // Show general error
    };

    const handleGoogleLogin = () => {
        // Redirect to your backend's Google login route
        window.location.href = 'https://www.studly.nu/login/google';
    };

    return (
        <motion.div
            className="login"
            initial={{ x: 100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.2, ease: 'easeOut' }}
        >
            <div className="login-logo">
                <img src={StudlyLogo} alt="Studly" className="logo-icon-login" />
            </div>
            <h2 className="login-heading">Logga in</h2>
            <form onSubmit={handleSubmit}>
                <label className="login-label">
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="Användarnamn"
                        required
                    />
                </label>

                <label className="login-label">
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Lösenord"
                        required
                    />
                </label>

                <div className="login-links">
                    <Link to="/forgot_password">
                        Glömt lösenord? <span className="highlight">Klicka här</span>
                    </Link>
                </div>
                <button type="submit" className="login-button">Logga in</button>
                <p id='orText'>Eller logga in med</p>
                <p id='Line'></p>
                <button onClick={handleGoogleLogin} className="google-login-button">
                    Google
                </button>
            </form>
            <p className="login-register">
                Jag har inget konto <Link to="/register">Registrera</Link>
            </p>
            {/* Popupdiscrete component */}
            {popup.visible && (
                <Popupdiscrete
                    message={popup.message}
                    type={popup.type}
                    onClose={() => setPopup({ message: '', type: '', visible: false })}
                />
            )}
        </motion.div>
    );
}

export default Login;
