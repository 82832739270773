import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import Popupdiscrete from './Popupdiscrete';
import Loading from './Loading';

const stripePromise = loadStripe('pk_test_51QRCvhGk5YejnsqNpbaf7X4rmzf4ycILTOlTMW2BGigLEfUmXA1u3tp7BaVFPGN0d1AdSTQZm4Z6aKNTPa6qzo0j00QJXQ4C8Y'); // Replace with your Stripe publishable key

const Membership = () => {
    const [isPro, setIsPro] = useState(false);
    const [popupMessage, setPopupMessage] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);

        fetch('https://www.studly.nu/current_user', {
            method: 'GET',
            credentials: 'include',
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.username) {
                    return fetch('https://www.studly.nu/get_user_data', {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        credentials: 'include',
                        body: JSON.stringify({ username: data.username }),
                    });
                } else {
                    throw new Error('Ingen användare inloggad');
                }
            })
            .then((res) => res.json())
            .then((userData) => {
                if (userData.rank === "Pro användare") {
                    setIsPro(true);
                } else if (userData.error) {
                    setPopupMessage({ message: userData.error, type: 'error' });
                }
                setLoading(false);
            })
            .catch((err) => {
                setPopupMessage({ message: err.message || 'Fel vid hämtning av medlemsdata.', type: 'error' });
                setLoading(false);
            });
    }, []);

    const handleProUpgrade = async () => {
        setLoading(true);
        try {
            const res = await fetch('https://www.studly.nu/upgrade_to_pro', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
            });
            const data = await res.json();
            console.log("Response from upgrade_to_pro:", data); // Debug
            if (data.sessionId) {
                const stripe = await stripePromise;
                console.log("Redirecting to Stripe Checkout with sessionId:", data.sessionId); // Debug
                await stripe.redirectToCheckout({ sessionId: data.sessionId });
            } else {
                setPopupMessage({ message: data.error || 'Uppgraderingen misslyckades. Vänligen försök igen.', type: 'error' });
            }
        } catch (error) {
            console.error("Error during Stripe Checkout:", error); // Debug
            setPopupMessage({ message: 'Fel vid bearbetning av betalning. Vänligen försök igen senare.', type: 'error' });
        }
        setLoading(false);
    };
    

    return (
        <div className="membership-container">
            {loading && <Loading />}
            <h2>Medlemskap</h2>
            <p>Nuvarande status: {isPro ? 'Pro Member' : 'Free Member'}</p>

            {!isPro && (
                <div className="upgrade-section">
                    <h3>Uppgradera till Pro</h3>
                    <p>Få tillgång till ytterligare funktioner och fördelar med ett Pro-medlemskap!</p>
                    <button onClick={handleProUpgrade} className="pay-now-button">
                        Betala nu(Test)
                    </button>
                </div>
            )}

            {popupMessage && (
                <Popupdiscrete
                    message={popupMessage.message}
                    type={popupMessage.type}
                    onClose={() => setPopupMessage(null)}
                />
            )}
        </div>
    );
};

export default Membership;
