import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link from React Router
import './Courses.css';

const subjects = [
    {
        category: 'Matematik',
        courses: [
            { name: 'Matematik 3C', path: '/courses/Matematik3c', description: 'Matte 3 handlar om olika typer av funktioner, som polynom, exponential- och trigonometriska funktioner.' },
            { name: 'Matematik 4', path: '/courses/Matematik4', description: 'Matte 4 bygger vidare på tidigare funktioner och introducerar derivata och integraler.' },
            { name: 'Matematik 5', path: '/courses/Matematik5', description: 'Matte 5 fördjupar sig i avancerade funktioner och tillämpningar.' },
        ],
    },
    {
        category: 'Fysik',
        courses: [
            { name: 'Fysik 3', path: '/courses/Fysik3', description: 'Fysik 3 inkluderar grundläggande fysikaliska tillämpningar och mekanik.' },
            { name: 'Fysik 4', path: '/courses/Fysik4', description: 'Fysik 4 täcker rörelselagar och energiprinciper.' },
            { name: 'Fysik 5', path: '/courses/Fysik5', description: 'Fysik 5 fokuserar på vågor och elektromagnetism.' },
        ],
    },
    {
        category: 'Kemi',
        courses: [
            { name: 'Kemi 3', path: '/courses/Kemi3', description: 'Kemi 3 handlar om kemiska beräkningar och stökiometri.' },
            { name: 'Kemi 4', path: '/courses/Kemi4', description: 'Kemi 4 täcker kinetik och termodynamik.' },
            { name: 'Kemi 5', path: '/courses/Kemi5', description: 'Kemi 5 fördjupar sig i kemiska jämvikter.' },
        ],
    },
];

const Courses = () => {
    const [expandedCard, setExpandedCard] = useState(null);

    const toggleExpand = (cardIndex) => {
        setExpandedCard(expandedCard === cardIndex ? null : cardIndex);
    };

    return (
        <div className="courses-container">
            {subjects.map((subject, subjectIndex) => (
                <div key={subjectIndex} className="subject-section">
                    <h2 className="subject-title">{subject.category}</h2>
                    <div className="course-grid">
                        {subject.courses.map((course, idx) => {
                            const cardIndex = `${subjectIndex}-${idx}`;
                            const isExpanded = expandedCard === cardIndex;
                            return (
                                <div 
                                    key={cardIndex} 
                                    className={`course-card ${isExpanded ? 'expanded' : ''}`}
                                >
                                    <div className="card-content">
                                        <h3 className="course-title">{course.name}</h3>
                                        <div className="course-description">
                                            <p>
                                                {course.description}
                                                {!isExpanded && (
                                                    <span 
                                                        className="more-text" 
                                                        onClick={() => toggleExpand(cardIndex)}
                                                    >
                                                        {' '}mer...
                                                    </span>
                                                )}
                                            </p>
                                            <div className={`extra-content ${isExpanded ? 'show' : ''}`}>
                                                <p className="extra-text">
                                                    Här finns ytterligare fördjupad information om ämnet. Du kan lära dig om olika tekniker, formler och tillämpningar som är relevanta för denna kurs.
                                                    <span 
                                                        className="more-text" 
                                                        onClick={() => toggleExpand(cardIndex)}
                                                    >
                                                        {' '}mindre..
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            <Link to={course.path} className="go-btn">
                                                Till kursen
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Courses;
